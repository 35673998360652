<template>
    <div>
        <v-dialog
            v-model="dialog"
            width="500"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    :color="workOrderComment ? 'secondary lighten-2' : 'primary lighten-1'"
                    dark
                    small
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon size="16" class="mr-3">fas fa-comments-question</v-icon> {{ btnLabel }}
                </v-btn>
            </template>

            <v-card>
                <v-card-title>
                    <v-icon size="16" class="mr-3">far fa-comments-question</v-icon> {{ workOrderComment ? 'Vraag beantwoorden' : 'Stel een vraag'}}
                </v-card-title>

                <v-card-text>
                    <v-row class="mt-5">
                        <v-col
                            cols="12"
                            md="12"
                        >
                            <p v-if="workOrderComment"><b>"{{ workOrderComment.question }}"</b></p>
                            <v-textarea
                                v-model="question"
                                solo
                                :label="textAreaLabel"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn color="secondary text" text @click="closeDialog">sluiten</v-btn>
                    <v-btn
                        color="primary"
                        class="ml-auto"
                        @click="submitComment"
                        :disabled="isSubmitting"
                    >
                        <v-icon size="16" class="mr-3">fas fa-paper-plane</v-icon> Verstuur
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

export default {
    name: "WorkOrderCommentsModal",
    data () {
        return {
            dialog: false,
            question: null,
            isSubmitting: false,
        }
    },
    props: {
        workOrderId: {type: Number, required: true},
        workOrderComment: {type: Object, default: null, required: false},
        btnLabel: {type: String, required: true},
    },
    computed: {
        textAreaLabel() {
            return null !== this.workOrderComment ? 'Type hier je antwoord...' : 'Type hier je vraag...'
        }
    },
    methods: {
        submitComment() {
            this.isSubmitting = true;
            if (!this.question) {
                return
            }

            if (null !== this.workOrderComment) {
                // Question answered
                this.$store.dispatch('sentWorkOrderCommentAnswer', { 'workOrderCommentId': this.workOrderComment.id, answer: this.question })
                    .then(() => {
                        this.$store.dispatch('getWorkOrder', {workOrderId: this.workOrderId})
                    })
            } else {
                // Question asked
                this.$store.dispatch('sentWorkOrderComment', { 'workOrderId': this.workOrderId, question: this.question })
                    .then(() => {
                        this.$store.dispatch('getWorkOrder', {workOrderId: this.workOrderId})
                    })
            }

            this.question = null;
            this.isSubmitting = false;
            this.dialog = false;
        },
        closeDialog() {
            this.dialog = false
        }
    }
}
</script>

<style scoped>

</style>
