<template>
    <div>
        <v-card v-if="!workOrder.signedAt">
            <v-card-title>Toevoegen</v-card-title>
            <v-card-text class="mb-10">
                <TimeRegistrationRow
                    :active-date="activeDate"
                    :active-user="selectedUser"
                    :work-order-id="workOrder.id"
                    multi-user
                    @reloadTimeRegistration="reloadWorkOrder"
                />
            </v-card-text>
        </v-card>

        <h2>Historie</h2>
        <v-divider></v-divider>

        <TimeRegistrationsDataTable
            :timeRegistrations="workOrder.timeRegistrations"
            :compactView="true"
            :timesEditable="!workOrder.signedAt"
            @reloadTimes="reloadWorkOrder"
        />
    </div>
</template>

<script>
import TimeRegistrationRow from "@/components/timeRegistration/TimeRegistrationRow.vue";
import {mapState} from "vuex";
import TimeRegistrationsDataTable from "../timeRegistration/TimeRegistrationsDataTable.vue";

export default {
    name: "TimeRegistration",
    components: {TimeRegistrationsDataTable, TimeRegistrationRow},
    props: {
        workOrder: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            activeDate: new Date().toISOString().split('T')[0],
            selectedUser: this.$store.state.auth.user.id,
            headers: [
                { text: 'Datum en tijd', value: 'date' },
                { text: 'Medewerker', value: 'user.name' },
                { text: 'Tijd', value: 'minutesWorked' }
            ],
        }
    },
    computed: {
        ...mapState(['timeRegistration']),
    },
    methods: {
        timeWorked(input) {
            let hours = Math.floor(input / 60);
            let minutes = input % 60;
            if (minutes < 10) minutes = '0' + minutes;

            return `${hours}:${minutes}`;
        },
        reloadWorkOrder() {
            this.$emit('reloadWorkOrder')
        }
    }
}
</script>

<style scoped>
    hr{
        margin: 8px 0;
    }
</style>
