<template>
    <div>
        <v-card class="mb-5" style="max-width:600px" v-if="showAddFilesForm">
            <v-card-text>
                <v-form
                    validate-on="submit"
                    @submit.prevent="submitFiles"
                    ref="fileSubmitForm"
                    >
                    <p>Upload een bestand</p>
                    <div class="d-flex flex-wrap">
                        <v-file-input
                            counter
                            multiple
                            show-size
                            small-chips
                            truncate-length="15"
                            outlined
                            required
                            dense
                            v-model="files"
                            :rules="fileRules"
                        />
                        <v-btn color="secondary" class="ml-3" type="submit" :disabled="files.length < 1">
                            Uploaden<v-icon size="16" class="ml-2">far fa-upload</v-icon>
                        </v-btn>
                    </div>
                </v-form>
            </v-card-text>
        </v-card>

        <h2 class="mb-5">Bestanden</h2>

        <div class="workOrderFiles">
            <v-row v-if="workOrderFiles.length > 0">
                <v-col cols="12" sm="6" md="4" lg="2" v-for="(file, index) in workOrderFiles" :key="index">
                    <div class="file-container">
                        <v-card v-if="isImage(file)">
                            <v-card-text>
                                <v-img
                                    :src="file.url"
                                    :aspect-ratio="1"
                                    class="image-enlarge"
                                    @click.stop="expandImage(file.url, file.name)"
                                    :alt="file.name"
                                    title="Vergroten"
                                />
                            </v-card-text>
                        </v-card>
                        <v-card :href="file.url" target="_blank" v-else>
                            <v-card-text class="text-center regular-file">
                                <div class="icon-container">
                                    <v-icon
                                        class="d-block mb-3"
                                        size="40"
                                    >
                                        fal fa-file-alt
                                    </v-icon>
                                    {{ file.name }}
                                </div>
                            </v-card-text>
                        </v-card>
                        <v-btn
                            fab
                            dark
                            small
                            @click="deleteFile(file.id)"
                            color="red"
                            absolute
                            top
                            right
                        >
                            <v-icon size="16">far fa-trash-alt</v-icon>
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <p v-else>Er zijn geen bestanden toegevoegd aan de ze werkbon</p>
        </div>
    </div>
</template>

<script>
import apiClient from "@/api";
import {Fancybox} from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

export default {
    name: "WorkOrderFiles",
    props: {
        workOrderId: {type: Number},
        workOrderFiles: {type: []},
        showAddFilesForm: {type:Boolean, default: true, required: false},
    },
    data() {
        return {
            files: [],
            fileRules: [v => v.length > 0 || 'Kies een bestand'],
        }
    },
    methods: {
        submitFiles() {
            const formData = new FormData();
            this.files.forEach(file => {
                formData.append('file_'+Math.random(), file);
            })
            this.$refs.fileSubmitForm.reset();
            const headers = { 'Content-Type': 'multipart/form-data' };
            apiClient.post(`/workorders/${this.workOrderId}/files`, formData, { headers })
                .then(() => {
                    this.$store.dispatch('getWorkOrder', { workOrderId: this.workOrderId })
                    this.$store.dispatch('showSnackbar', {text: 'Bestanden opgeslagen'})
                })
                .catch(() => {
                    this.$store.dispatch('showSnackbar', {text: 'Er ging iets fout'})
                })
        },
        deleteFile(fileId) {
            apiClient.post(`/workorders/${this.workOrderId}/files/${fileId}/delete`)
                .then(() => {
                    this.$store.dispatch('getWorkOrder', { workOrderId: this.workOrderId })
                    this.$store.dispatch('showSnackbar', {text: 'Bestand verwijderd'})
                })
                .catch(() => {
                    this.$store.dispatch('showSnackbar', {text: 'Er ging iets fout'})
                })
        },
        isImage(file){
            if(file && file.mimeType.includes('image/')){
                return true;
            }else{
                return false;
            }
        },
        expandImage(image, title) {
            new Fancybox([
                {
                    src: image,
                    caption: title
                },
            ]);
        }
    }
}
</script>

<style scoped>
    .file-container{
        position: relative;
    }
    .regular-file{
        position: relative;
        padding:0;
        padding-bottom:100%;
    }
    .regular-file .icon-container{
        position: absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
</style>
