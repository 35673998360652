<template>
    <div>
        <v-form ref="materialAddForm" v-model="valid">

            <div class="d-block d-sm-flex align-end bg-gray pa-5 pt-2 ">
                <v-text-field
                    v-model="quantity"
                    :rules="quantityRules"
                    type="number"
                    min="0"
                    class="flex-shrink-1"
                    label="Aantal*"
                    hide-details
                />
                <v-text-field
                    v-model="article"
                    :rules="articleRules"
                    type="text"
                    class="ml-0 ml-sm-10 mb-5 mb-sm-0"
                    label="Materiaal*"
                    append-icon="fal fa-shopping-cart"
                    @click:append="open2Ba"
                    hide-details
                />
                <v-select
                    v-model="unit"
                    :items="unitItems"
                    item-text="unit"
                    item-value="value"
                    label="Eenheid*"
                    :rules="unitRules"
                    class="ml-0 ml-sm-10 mb-5 mb-sm-0"
                    hide-details
                ></v-select>
                <v-btn
                    type="submit"
                    color="primary"
                    class="ml-0 ml-sm-10"
                    :disabled="!valid"
                    @click="submitForm"
                >
                    Toevoegen
                    <v-icon size="14" class="ml-2">fal fa-plus</v-icon>
                </v-btn>
            </div>
        </v-form>
    </div>
</template>

<script>
import {serialize} from '@/utils/utils.js'
import apiClient from "@/api"

export default {
    name: "MaterialAddForm",
    data: () => ({
        dialog: false,
        quantity: null,
        article: '',
        responseUniFeed: null,
        valid: false,
        quantityRules: [v => v > 0 || 'Moet minimaal 1 zijn'],
        unitRules: [v => v !== null || 'Kies een eenheid'],
        articleRules: [v => v !== null && v.length > 1 || 'Mag niet leeg zijn'],
        unit: null,
        unitItems: [
            {unit: 'Meters', value: 'Meter'},
            {unit: 'Stuk(s)', value: 'Stuk'}
        ],
    }),
    props: {
        workOrder: {type: Object, required: true}
    },
    methods: {
        async open2Ba() {
            const token = await apiClient.get('/2ba/get-access-token')
                .then(response => {
                    return response.data.accessToken
                })

            let hUrl = window.location.href.split('#')[0]
            let params = {
                accessToken: token,
                interfaceType: 'JSONGET',
                interface: 1, // Select 1 trade item
                hookUrl: hUrl + '#material',
                query: '',
            }

            let url = 'https://unifeed.2ba.nl/?' + serialize(params)
            window.location.assign(url)
        },
        submitForm(e) {
            e.preventDefault();

            let articleNumber = 'Artikel'
            let articleName = this.article
            // Check if article is from 2BA
            if (this.article.indexOf(' | ') !== -1) {
                const articleSplitted = this.article.split(' | ')
                articleNumber = articleSplitted[0]
                articleName = articleSplitted[1]
            }

            let article = {
                articleNumber,
                articleName,
                quantity: this.quantity,
                unit: this.unit,
            }

            this.$store.dispatch('addMaterial', {
                workOrderId: this.workOrder.id,
                'article': article,
            }).then(() => {
                this.$store.dispatch('showSnackbar', {text: 'Materiaal toegevoegd'})
                this.$emit('reloadWorkOrder')
                this.responseUniFeed = null
                this.$refs.materialAddForm.reset()
            }).catch(() => {
                this.$store.dispatch('showSnackbar', {text: 'Fout bij opslaan', color: 'red darken-2'})
            })
        },
        applySupplierPrefix(name, articleNr) {
            const suppliers = [
                {name: 'Technische Unie', prefix: 'TU',},
                {name: 'Van Egmond', prefix: 'EG',},
                {name: 'Rexel Nederland', prefix: 'RE',},
                {name: 'Oosterberg', prefix: 'OO',},
                {name: 'itsme', prefix: 'IT',},
            ]

            let supplier = suppliers.find(supplier => supplier.name === name)

            return supplier.prefix+articleNr
        },
    },
    mounted() {
        if (this.$route.hash && this.$route.hash.slice(0, 9) === '#material') {
            const rawJson = this.$route.hash.split('json=')[1]
            const data = JSON.parse(decodeURIComponent(rawJson))
            this.responseUniFeed = data[0]
            let articleNr = this.applySupplierPrefix(this.responseUniFeed.SupplierName, this.responseUniFeed.SuppliersTradeItemId)
            this.article = `${articleNr} | ${this.responseUniFeed.Description}`
            this.$router.push(this.$route.path)
        }
    },
}
</script>

<style scoped>

</style>
