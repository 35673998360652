<template>
    <div>
        <v-btn class="ml-3" @click="showOverlay = !showOverlay"><v-icon size="16" class="mr-2">far fa-signature</v-icon>Handtekening</v-btn>

        <v-overlay :value="showOverlay">
            <v-card light>
                <v-card-title><v-icon size="16" class="mr-2">far fa-signature</v-icon>Handtekening</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <VueSignaturePad
                        ref="signaturePad"
                        class="signaturePad"
                        style="background-color:#eee;"
                    />
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn @click="showOverlay = !showOverlay"><v-icon size="16" class="mr-2">far fa-chevron-left</v-icon>Terug</v-btn>
                    <v-btn @click="resetSignature" class="ml-5"><v-icon size="16">far fa-undo</v-icon></v-btn>
                    <v-btn @click="saveSignature" class="ml-3"><v-icon size="16" class="mr-2">far fa-floppy-disk-circle-arrow-right</v-icon>Bevestigen</v-btn>
                </v-card-actions>
            </v-card>
        </v-overlay>
    </div>
</template>

<script>
import {VueSignaturePad} from "vue-signature-pad";

export default {
    name: "WorkOrderSignature",
    props: {
        workOrderId: {type: Number},
    },
    components: {VueSignaturePad},
    data() {
        return {
            showOverlay: false,
        }
    },
    methods: {
        saveSignature() {
            const { data } = this.$refs.signaturePad.saveSignature();
            this.$store.dispatch('signWorkOrder', {id:this.workOrderId, signatureData: data})
            this.showOverlay = false
        },
        resetSignature() {
            this.$refs.signaturePad.undoSignature();
        }
    }
}
</script>

<style scoped>
.signaturePad {
    width: 80vw;
    max-width: 500px;
    aspect-ratio: 1.5;
}
</style>
