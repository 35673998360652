<template>
    <v-container>
        <v-card elevation="24">
            <v-tabs dark v-model="tab" grow slider-color="primary">
                <v-tab key="#een"><v-icon size="18">fal fa-info-circle</v-icon><span class="ml-4" v-if="!isMobile">Werkdetails</span></v-tab>
                <v-tab key="#twee"><v-icon size="18">fal fa-timer</v-icon><span class="ml-4" v-if="!isMobile">Uren</span></v-tab>
                <v-tab key="#drie"><v-icon size="18">fal fa-screwdriver-wrench</v-icon><span class="ml-4" v-if="!isMobile">Materialen</span></v-tab>
                <v-tab key="#vier"><v-icon size="18">fal fa-paperclip</v-icon><span class="ml-4" v-if="!isMobile">Bestanden</span></v-tab>
            </v-tabs>
            <v-card-text>
                <v-tabs-items v-model="tab" v-if="workOrder" class="overflow-visible">
                    <v-tab-item>
                        <WorkOrderDetails :work-order="workOrder"/>
                    </v-tab-item>
                    <v-tab-item>
                        <TimeRegistration
                            :work-order="workOrder"
                            @reloadWorkOrder="loadWorkOrder"
                        />
                    </v-tab-item>
                    <v-tab-item>
                        <h2 class="mb-5">Gebruikte materialen</h2>
                        <MaterialAddForm :work-order="workOrder" @reloadWorkOrder="loadWorkOrder" v-if="!workOrder.signedAt"/>
                        <v-divider></v-divider>

                        <Materials :work-order="workOrder" @reloadWorkOrder="loadWorkOrder"/>

                        <v-divider class="mb-10 mt-10" v-if="!workOrder.signedAt"></v-divider>
                        <v-card style="max-width:600px" v-if="!workOrder.signedAt">
                            <v-card-title>Materiaal bestellen</v-card-title>
                            <v-card-text>
                                <p>Plaats bestellingen voor 15:00 om ze de volgende werkdag te ontvangen</p>
                                <PlaceMaterialOrder :workOrderId="workOrderId" />
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <WorkOrderFiles
                            :work-order-id="workOrder.id"
                            :work-order-files="workOrder.files"
                            :showAddFilesForm="!workOrder.signedAt"
                        />
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import WorkOrderDetails from "@/components/workOrder/WorkOrderDetails";
import TimeRegistration from "@/components/workOrder/TimeRegistration";
import Materials from "@/components/workOrder/Materials";
import WorkOrderFiles from "@/components/workOrder/WorkOrderFiles.vue";
import MaterialAddForm from "@/components/workOrder/MaterialAddForm.vue";
import PlaceMaterialOrder from "@/components/materialOrder/PlaceMaterialOrder.vue";

export default {
    name: "WorkOrderDetailView",
    components: {
        PlaceMaterialOrder,
        MaterialAddForm, WorkOrderFiles, Materials, TimeRegistration, WorkOrderDetails
    },
    data: () => ({
        tab: null,
    }),
    computed: {
        workOrderId() {
            return parseInt(this.$route.params.id)
        },
        workOrder() {
            return this.$store.state.workOrder.workOrder
        },
        isMobile() { return ['xs', 'sm'].includes(this.$vuetify.breakpoint.name) }
    },
    methods: {
        loadWorkOrder() {
            this.$store.dispatch('getWorkOrder', {workOrderId: this.workOrderId})
        }
    },
    mounted() {
        this.loadWorkOrder();

        if (this.$route.hash) {
            this.tab = 2
        }
    },
    watch: {
        $route(to, from) {
            if (to !== from) {
                this.loadWorkOrder();
            }
        },
    },
}
</script>

<style scoped>
.v-tab{
    min-width:50px;
}
hr{
    margin: 8px 0;
}
</style>
