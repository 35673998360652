<template>
    <div class="workOrderDetails">
        <v-row>
            <v-col cols="12" lg="6">
                <v-card>
                    <v-card-title>
                        Gegevens
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <div>
                                    <h3>{{ project.customerName }}</h3>
                                </div>
                            </v-col>
                            <v-col cols="12" lg="6">
                                <div>
                                    <h4>Adres</h4>
                                    <p>
                                        {{ project.address }}<br>
                                        {{ project.postalCode }}<br>
                                        {{ project.city }}
                                    </p>
                                </div>
                            </v-col>
                            <v-col cols="12" lg="6">
                                <div>
                                    <h4 class="mb-2">Contact</h4>
                                    <p class="mb-2" v-if="project.phoneNumber"><a :href="project.phoneNumber"><v-icon small>far fa-phone</v-icon> {{ project.phoneNumber }}</a></p>
                                    <p class="mb-2" v-if="project.email"><a :href="'mailto:'+project.email"><v-icon small>far fa-envelope</v-icon> {{ project.email }}</a></p>
                                </div>
                            </v-col>
                        </v-row>
                        <v-divider class="mt-3 mb-6"></v-divider>
<!--                        <p class="mb-0"><b>{{ workOrder.project.projectNo }}:</b> {{ workOrder.project.name }}</p>-->
                        <p>
                            <b>Werkbon:</b> {{ workOrder.workOrderNo }}<br />
                            <b>Boeknr:</b> {{ workOrder.voucherNumber }}<br />
                            <b>Datum:</b> {{ workOrder.appointmentDateAt|luxon }}
                        </p>
                        <p><b>Beschrijving:</b><br />{{ workOrder.description }}</p>

                        <div class="d-flex justify-space-between align-center">
                            <b>Opmerkingen:</b>
                            <EditRemarks
                                v-if="!workOrder.signedAt"
                                :workOrderId="workOrder.id"
                                :workOrderRemarks="workOrder.remarks ?? ''"
                            />
                        </div>
                        <p>{{ workOrder.remarks }}</p>


                    </v-card-text>
                    <v-card-actions>
                        <WorkOrderSignature
                            v-if="!workOrder.signedAt"
                            :work-order-id="workOrder.id"
                        />
                        <v-btn :href="downloadUrl" target="_blank" class="ml-auto"><v-icon size="16" class="mr-3">fas fa-file-pdf</v-icon> Download PDF</v-btn>
                    </v-card-actions>
                </v-card>

            </v-col>
            <v-col cols="12" lg="6">
                <v-card>
                    <v-card-title>
                        Vragen
                    </v-card-title>
                    <v-card-text>
                        <v-list v-if="workOrder.workOrderComments.length > 0">
                            <v-list-item v-for="(item, index) in workOrder.workOrderComments" :key="index" class="px-0">
                                <v-list-item-avatar color="#f5f5f5">
                                    <v-icon size="18">fal fa-comments-question</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <div class="d-flex align-center">
                                        <div class="mr-3">
                                            <v-list-item-title
                                                class="text-wrap"
                                                v-html="item.question"
                                                :class="{ 'mb-1' : item.answer, 'mb-2' : !item.answer}"
                                            />
                                            <v-list-item-subtitle class="text-wrap" v-html="item.answer" v-if="item.answer"/>
                                            <WorkOrderCommentsModal v-else :workOrderId="workOrder.id" :workOrderComment="item" btn-label="Beantwoorden" class="ml-auto"/>
                                        </div>
                                        <v-btn
                                            @click="openDelete(item)"
                                            v-if="$store.getters.userIsAdmin && !workOrder.signedAt"
                                            small
                                            text
                                            class="ml-auto mt-6"
                                            color="red"
                                        >
                                            <v-icon size="16">fas fa-trash-alt</v-icon>
                                        </v-btn>
                                    </div>
                                    <div class="d-flex align-center mt-2">
                                        <v-icon size="13" class="me-2">fas fa-user</v-icon>
                                        <small>{{ item.questionUser.name }}</small>
                                    </div>
                                    <v-divider class="mt-3"></v-divider>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <p v-else>Geen vragen</p>
                    </v-card-text>
                    <v-card-actions>
                        <WorkOrderCommentsModal
                            v-if="!workOrder.signedAt"
                            :workOrderId="workOrder.id"
                            btn-label="Stel een vraag"
                            class="ml-auto"
                        />
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="text-center d-block">Bevestigen</v-card-title>
                <v-card-text>
                    <div>
                        <v-card-subtitle class="text-center">Weet je zeker dat je de vraag <strong>"{{ editedItem.question }}"</strong> wilt verwijderen?</v-card-subtitle>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="closeDelete">Annuleren</v-btn>
                    <v-btn color="green darken-1" text @click="deleteComment">Ja</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import WorkOrderCommentsModal from "./WorkOrderCommentsModal.vue";
import WorkOrderSignature from "@/components/workOrder/WorkOrderSignature.vue";
import EditRemarks from "./EditRemarks.vue";

export default {
    name: "WorkOrderDetails",
    components: {EditRemarks, WorkOrderCommentsModal, WorkOrderSignature},
    props: {
        workOrder: {type: Object, required: true}
    },
    data() {
        return {
            editedItem: false,
            dialogDelete: false,
        }
    },
    computed: {
        project() {
            return this.workOrder.project
        },
        downloadUrl() {
            const workOrderId = this.workOrder.id
            const filename = this.workOrder.voucherNumber+'_'+this.workOrder.project.name.replaceAll('.', '')
            return process.env.VUE_APP_API_URL + `workorders/${workOrderId}/${filename}.pdf`;
        },
    },
    methods: {
        deleteComment() {
            const currentQuestion = this.editedItem.id;
            this.$store.dispatch('deleteWorkOrderComment', {workOrderCommentId: currentQuestion})
                .then(() => {
                    this.dialogDelete = false;
                    this.$store.dispatch('getWorkOrder', {workOrderId: this.workOrder.id})
                    this.$store.dispatch('showSnackbar', {text: 'Vraag verwijderd'})
                })
        },
        closeDelete () {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = false
            })
        },
        openDelete(item) {
            this.dialogDelete = true;
            this.editedItem = item
        }
    }
}
</script>

<style scoped>
    hr{
        flex-basis: 100%;
        margin-top:20px;
    }
    .v-list-item .v-list-item__title{
        margin-top:20px;
    }
    .v-list-item__content{
        padding:0;
    }
</style>
