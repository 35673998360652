<template>
    <v-dialog v-model="dialog" width="500">

        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on">
                <v-icon size="18" color="primary">fas fa-edit</v-icon>
            </v-btn>
        </template>

        <v-card>
            <v-card-title class="text-h5">
                Opmerkingen
            </v-card-title>

            <v-card-text>
                <v-form>
                    <v-textarea
                        v-model="remarks"
                        outlined
                        dense
                    />
                </v-form>
            </v-card-text>

            <v-divider></v-divider>
            <v-card-actions>
                <v-btn @click="dialog = false">
                    Sluiten
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="saveRemarks" color="primary">
                    Opslaan
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "EditRemarks",
    props: {
        workOrderId: {type:Number, required:true},
        workOrderRemarks: {type:String, required:true}
    },
    data () {
        return {
            dialog: false,
            remarks: null,
        }
    },
    methods: {
        saveRemarks() {
            const workOrderId = this.workOrderId
            const remarks = this.remarks
            this.$store.dispatch('saveWorkOrderRemarks', {workOrderId, remarks})
                .then(() => {
                    this.$store.dispatch('showSnackbar', {text: 'Opmerkingen opgeslagen'})
                    this.$store.dispatch('getWorkOrder', {workOrderId})
                    this.remarks = null
                    this.dialog = false
                })
                .catch(() => {
                    this.$store.dispatch('showSnackbar', {text: 'Fout bij opslaan',  color: 'red darken2'})
                })
        }
    },
    mounted() {
        this.remarks = this.workOrderRemarks
    },
    watch: {
        workOrderRemarks(value) {
            this.remarks = value
        }
    }
}
</script>

<style scoped>

</style>
