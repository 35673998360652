<template>
    <div>
        <v-row>
            <v-col>
                <v-data-table
                    :headers="headers"
                    :items-per-page="15"
                    class="elevation-0"
                    :items="workOrder.materials"
                >
                    <template v-slot:item.quantity="{ item }">
                        <v-flex>
                            <v-text-field
                                :ref="'itemQuantity-'+item.id"
                                :value="item.quantity"
                                type="number"
                                min="1"
                                dense
                                hide-details
                                style="width:60px"
                                @input="updateQuantityForArticle('itemQuantity-'+item.id, item.id)"
                            />
                        </v-flex>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-flex>
                            <v-btn
                                class="js-confirm-action ml-auto"
                                @click="deleteRow(item)"
                                dark
                                small
                                text
                                color="red"
                            >
                                <i class="fas fa-trash-alt"></i>
                            </v-btn>
                        </v-flex>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="text-center d-block">Bevestigen</v-card-title>
                <v-card-text>
                    <div>
                        <v-card-subtitle class="text-center">Weet je zeker dat je <strong>{{ editedItem.articleName }}</strong> wilt verwijderen?</v-card-subtitle>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="closeDelete">Annuleren</v-btn>
                    <v-btn color="green darken-1" text @click="deleteItemConfirm">Ja</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: "Materials",
    props: {
        workOrder: {type: Object, required: true}
    },
    computed: {
        headers() {
            let headers = [
                { text: 'Artikelnummer', value: 'articleNumber' },
                { text: 'Omschrijving', value: 'articleName' },
                { text: 'Aantal', value: 'quantity' },
                { text: 'Eenheid', value: 'unit' },
            ]

            if (!this.workOrder.signedAt) {
                headers.push({ text: 'Actie', value: 'actions', align: 'end' })
            }

            return headers
        }
    },
    data() {
        return {
            dialogDelete: false,
            editedItem: false,
        }
    },
    methods: {
        closeDelete () {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = false
            })
        },
        deleteRow(item) {
            this.dialogDelete = true;
            this.editedItem = item
        },
        deleteItemConfirm () {
            this.dialogDelete = false;
            this.$store.dispatch('removeMaterial', {
                'workOrderId': this.workOrder.id,
                'materialId':  this.editedItem.id
            }).then(() => {
                this.$store.dispatch('showSnackbar', {text: 'Materiaal verwijderd'})
                this.$emit('reloadWorkOrder')
            }).catch(() => {
                this.$store.dispatch('showSnackbar', {text: 'Er ging is fout', color: 'red darken2'})
            })
        },
        updateQuantityForArticle (ref, itemId) {
            const value = parseInt(this.$refs[ref].lazyValue)
            if (!value || value === 0) return

            this.$store.dispatch('updateQuantityMaterial', {
                id: itemId,
                quantity: value,
            }).then(() => {
                this.$store.dispatch('showSnackbar', {text: 'Aantal aangepast'})
                this.$emit('reloadWorkOrder')
            }).catch(() => {
                this.$store.dispatch('showSnackbar', {text: 'Er ging iets fout', color: 'red darken2'})
            })
        }
    }
}
</script>

<style scoped>
.v-card__title{
    word-break: normal;
}
td.text-end{
    padding-right:0 !important;
}
</style>
